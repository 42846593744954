import React from 'react';
import {
	Routes, Route, Navigate,
} from 'react-router-dom';
import { Feedback } from './feedback';
import { Main } from './main';

export const RoomScreens: React.FunctionComponent = () => (
	<Routes>
		<Route
			path="/:roomName"
			element={<Main />}
		/>

		<Route
			path="/:roomName/feedback"
			element={<Feedback />}
		/>

		<Route path="*" element={<Navigate replace to="/error" />} />
	</Routes>
);
