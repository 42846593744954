export enum ZoomRoomStatus {
	NotStarted = 0,
	Started = 1,
	Ended = 2,
}

export enum UserRole {
	Admin = 0,
	User = 1,
	Mentor = 2,
	SubAdmin = 3,
}
