import React from 'react';
import { ReactComponent as CQLogo } from '../../assets/img/cq-logo.svg';

import styles from './header.module.css';

interface AppHeaderProps {
	className: string
	title?: string
	liveVisible?: boolean
}

export const AppHeader: React.FunctionComponent<AppHeaderProps> = (props) => {
	const { className, title, liveVisible } = props;

	return (
		<div className={className}>
			<div className={styles.logoContainer}>
				<CQLogo />
			</div>

			<div className={styles.title}>
				<span>{title}</span>
			</div>

			{
				liveVisible && (
					<div className={styles.liveLogo}>
						<div className={styles.greenDot} />
						<span>
							LIVE
						</span>
					</div>
				)
			}
		</div>
	);
};
