import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { AppState, useAppStore } from './stores';
import { logger } from './libs/utils';
import { MainScreen } from './screens';

import { ReactComponent as Loader } from './assets/icons/loader.svg';
import styles from './app.module.css';
import { APP_CONFIG } from './config';

const appStoreSelector = (state: AppState) => ({
	init: state.init,
	sessionData: state.sessionData,
});

export function App() {
	const {
		sessionData,
		init,
	} = useAppStore(appStoreSelector);

	const [loading, setLoading] = useState<boolean>(true);
	const [loadingText, setLoadingText] = useState<string>('authenticating...');

	useEffect(() => {
		(async () => {
			try {
				await init();
			} catch (e) {
				logger.error(e);

				setLoadingText('Authentication failed, redirecting you to the login...');

				setTimeout(() => {
					window.location.replace(`${APP_CONFIG.MainServerURL}/login?referer=${window.location.href}`);
				}, 2000);
			}
		})();
	}, [init]);

	useEffect(() => {
		if (sessionData) {
			setLoading(false);
		}
	}, [sessionData]);

	return (
		<Spin
			wrapperClassName={styles.wrapper}
			spinning={loading}
			indicator={<Loader />}
			size="large"
			tip={loadingText}
		>
			<MainScreen />
		</Spin>
	);
}
