import React from 'react';
import {
	Routes, Route, Navigate,
} from 'react-router-dom';
import { NotFoundScreen } from './not-found';

export const ErrorScreens: React.FunctionComponent = () => (
	<Routes>
		<Route
			path="/404"
			element={<NotFoundScreen />}
		/>

		<Route path="*" element={<Navigate replace to="404" />} />
	</Routes>
);
