import { EventEmitter } from 'events';
import TypedEmitter from 'typed-emitter';
import axios, { AxiosInstance } from 'axios';

interface CQMainClientOptions {
	cqMainServerURL: string
}

type ClientEvents = {
	'session-data': (data: any) => void
	'class-details': (data: any) => void
	'class-allowed-userIds': (data: any) => void
};

export class CQMainClient extends (EventEmitter as new () => TypedEmitter<ClientEvents>) {
	private _options: CQMainClientOptions;

	private _cqMainAPI: AxiosInstance;

	private _disposables:	(() => void)[] = [];

	// private _attachClientListeners = (): void => {};

	// private _detachClientListeners = (): void => {};

	constructor(options: CQMainClientOptions) {
		super();

		const {
			cqMainServerURL,
		} = options;

		const cqMainAPI = axios.create({
			baseURL: cqMainServerURL,
			responseType: 'json',
			withCredentials: true,
		});

		this._cqMainAPI = cqMainAPI;
		this._options = options;

		// this._attachClientListeners();

		this._disposables.push(
			// this._detachClientListeners,
			this.removeAllListeners.bind(this),
		);
	}

	destroy = async (): Promise<void> => {
		const { _disposables } = this;

		await Promise.all(_disposables.map((disposable) => disposable()));
		this._disposables = [];
	};

	getSessionData = async (): Promise<any> => {
		const response = await this._cqMainAPI.get('/api/session/data');

		const reqURL = `${this._cqMainAPI.defaults.baseURL}${this._cqMainAPI.getUri(response.config)}`;
		const resURL = response.request.responseURL;

		if (response.data.error) {
			throw new Error(response.data.error);
		}

		if (reqURL !== resURL) {
			throw new Error('Error while getting session data!');
		}

		this.emit('session-data', response.data);

		return response.data;
	};

	getClassDetails = async (className: string): Promise<any> => {
		const response = await this._cqMainAPI.get('/api/class/detail', {
			params: {
				name: className,
			},
		});

		if (response.data.error) {
			throw new Error(response.data.error);
		}

		this.emit('class-details', response.data);

		return response.data;
	};

	getClassAllowedUserIds = async (className: string): Promise<any> => {
		const response = await this._cqMainAPI.get('/api/class/getAllowedUsers', {
			params: {
				name: className,
			},
		});

		if (response.data.error) {
			throw new Error(response.data.error);
		}

		this.emit('class-allowed-userIds', response.data);

		return response.data;
	};

	submitFeedback = async ({
		zoomRoomId, fM1, fM2, fM3,
	}: any): Promise<void> => {
		const response = await this._cqMainAPI.post('/api/zoom-feedback/create', {
			zoomRoomId,
			feedbackMeasure1: fM1,
			feedbackMeasure2: fM2,
			feedbackMeasure3: fM3,
		});

		if (response.data.error) {
			throw new Error(response.data.error);
		}
	};
}
