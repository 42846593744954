import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ZoomMtg as ZoomMtgNamespace } from '@zoomus/websdk';
import { Button } from 'antd';
import { APP_CONFIG, UserRole, ZoomRoomStatus } from '../../../config';
import { AppState, useAppStore } from '../../../stores';
import { logger } from '../../../libs/utils';
import { AppHeader } from '../../../components/app-header';

import styles from './main.module.css';
import { UserAvatar } from '../../../components/avatar';

declare const ZoomMtg: typeof ZoomMtgNamespace;

const appStoreSelector = (state: AppState) => ({
	sessionData: state.sessionData,
	classDetails: state.classDetails,
	webinarDetails: state.webinarDetails,

	getClassDetails: state.getClassDetails,
	getClassAllowedUserIds: state.getClassAllowedUserIds,
	getWebinarDetails: state.getWebinarDetails,
	getWebinarStatus: state.getWebinarStatus,
});

export const Main: React.FunctionComponent = () => {
	const { roomName } = useParams();

	const {
		sessionData,
		classDetails,
		webinarDetails,
		getClassDetails,
		getClassAllowedUserIds,
		getWebinarDetails,
		getWebinarStatus,
	} = useAppStore(appStoreSelector);

	const [notAllowed, setNotAllowed] = useState<boolean>(false);
	const [alreadyEnded, setAlreadyEnded] = useState<boolean>(false);
	const [hasStarted, setHasStarted] = useState<boolean>(false);

	useEffect(() => {
		try {
			if (roomName) {
				getClassDetails(roomName);
				getClassAllowedUserIds(roomName);
			}
		} catch (e) {
			logger.error(e);
		}
	}, [
		roomName, getClassDetails, getClassAllowedUserIds, getWebinarDetails,
	]);

	useEffect(() => {
		if (classDetails?.zoomRoomId) {
			getWebinarDetails(classDetails.zoomRoomId);
		}

		if (classDetails?.desc) {
			document.title = classDetails?.desc;
		} else {
			document.title = 'Class | CodeQuotient';
		}
	}, [classDetails, getWebinarDetails]);

	useEffect(() => {
		if (classDetails?.completed) {
			setAlreadyEnded(true);
			return;
		}

		if (webinarDetails && webinarDetails.status !== ZoomRoomStatus.Started) {
			if (classDetails?.time && classDetails?.duration) {
				logger.log(classDetails.time, classDetails.duration);

				const time = new Date(classDetails.time);
				time.setMinutes(time.getMinutes() + classDetails.duration);

				if (time < new Date()) {
					setAlreadyEnded(true);
				}
			}
		}
	}, [classDetails, webinarDetails]);

	useEffect(() => {
		if (
			parseInt(sessionData?.role, 10) === UserRole.User
			&& classDetails?.forCourse
			&& Array.isArray(classDetails?.allowedUserIds)
		) {
			const isFound = classDetails.allowedUserIds.findIndex(
				(uId: string) => uId === sessionData?.userId,
			);

			if (isFound < 0) {
				logger.error('user not allowed!');

				setNotAllowed(true);

				setTimeout(() => {
					window.location.replace(APP_CONFIG.MainServerURL);
				}, 3000);
			}
		}
	}, [classDetails, sessionData]);

	useEffect(() => {
		let intervalId: NodeJS.Timer;
		if (webinarDetails?.status === ZoomRoomStatus.Started) {
			setHasStarted(true);
		} else {
			intervalId = setInterval(() => {
				getWebinarStatus(classDetails?.zoomRoomId);
			}, 20000);
		}

		return () => clearInterval(intervalId);
	}, [webinarDetails, classDetails?.zoomRoomId, getWebinarStatus]);

	const startMeeting = useCallback((signature: string) => {
		const el = document.getElementById('zmmtg-root');
		if (!el) {
			return;
		}

		el.style.display = 'block';

		ZoomMtg.init({
			leaveUrl: classDetails?.disableFeedback ? APP_CONFIG.MainServerURL : `/room/${roomName}/feedback`,
			success: (success: any) => {
				logger.log(success);

				ZoomMtg.join({
					signature,
					meetingNumber: classDetails?.zoomRoomId as string,
					userName: sessionData.displayname,
					apiKey: APP_CONFIG.ZoomApiKey,
					userEmail: (
						(
							sessionData?.userId === classDetails?.presenterId
						|| classDetails?.TAIds?.includes(sessionData?.userId)
						) && (
							classDetails?.zoomHostId === webinarDetails?.hostId
						)
					) ? webinarDetails?.hostEmail
						: sessionData.email,
					passWord: classDetails?.code,
					success: (s: any) => {
						logger.log(s);
					},
					error: (error: any) => {
						logger.log(error);
					},
				});
			},
			error: (error: any) => {
				logger.log(error);
			},
		});
	}, [sessionData, classDetails, roomName, webinarDetails]);

	const getSignature = useCallback((e: any) => {
		e.preventDefault();

		fetch(`${APP_CONFIG.ZoomAdapterURL}/api/signature`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				meetingNumber: classDetails?.zoomRoomId,
				role: (
					sessionData?.userId === classDetails?.presenterId
					|| classDetails?.TAIds?.includes(sessionData?.userId)
				) ? '1' : '0',
			}),
		}).then((res) => res.json())
			.then((response) => {
				startMeeting(response.signature);
			}).catch((error) => {
				logger.error(error);
			});
	}, [sessionData, classDetails, startMeeting]);

	return (
		<div className={styles.container}>
			<AppHeader
				liveVisible={!classDetails?.completed && hasStarted}
				className={styles.header}
				title={classDetails?.desc}
			/>
			{
				(
					(classDetails?.completed || notAllowed || alreadyEnded)
					&& (
						sessionData?.userId !== classDetails?.presenterId
						&& !classDetails?.TAIds?.includes(sessionData?.userId)
					)
				)
					? (
						<div className={`${styles.main} ${styles.ended}`}>
							{
								notAllowed ? (
									<div>
										<p className={styles.preText}>
											Sorry...
										</p>
										<h1 className={styles.heading}>
											You&apos;re not allowed in this class.
										</h1>
										<p className={styles.subheading}>
											Redirecting you to your dashboard.
										</p>
									</div>
								) : (
									<div>
										<p className={styles.preText}>
											Oops...
										</p>
										<h1 className={styles.heading}>
											The class is already over!
										</h1>
										<p className={styles.subheading}>
											Please go to your dashboard to download the recording.
										</p>
									</div>
								)
							}
						</div>
					) : (
						<div className={styles.main}>
							<div className={styles.leftPane}>
								<div>
									<p className={styles.preText}>
										Welcome
										{' '}
										{sessionData?.displayname}
										,
									</p>
									{
										hasStarted ? (
											<>
												<h1 className={styles.heading}>
													Class in progress...
												</h1>
												<p className={styles.subheading}>
													Please click Join to enter LIVE classroom
												</p>
											</>
										) : (
											<>
												<h1 className={styles.heading}>
													Class has not started yet...
												</h1>
												<p className={styles.subheading}>
													{
														((
															sessionData?.userId === classDetails?.presenterId
													|| classDetails?.TAIds?.includes(sessionData?.userId)
														) && !hasStarted)
															? '' : 'Please wait for the teacher to start the class'
													}
												</p>
											</>
										)
									}
								</div>
							</div>
							<div className={styles.rightPane}>
								<div>
									{
										webinarDetails && hasStarted ? (
											<div className={styles.participantsContainer}>
												{
													Array.isArray(webinarDetails.participants)
													&& webinarDetails.participants.length
														? (
															<div className={styles.avatarsContainer}>
																{
																	webinarDetails.participants.slice(0, 3)
																		.map((p: any, i: number) => (
																			<div className={styles[`avatar-${i}`]} key={p.id}>
																				<UserAvatar
																					displayName={p.user_name}
																					id={p.id}
																					size={40}
																				/>
																			</div>
																		))
																}
															</div>
														) : null
												}
												{
													Array.isArray(webinarDetails.participants)
												&& webinarDetails.participants.length
														? (
															<span>{`${webinarDetails.participants.slice(0, 3).map((p: any) => p.user_name).join(', ')} and others are already in the class!`}</span>
														) : (
															<span>No one has joined yet!</span>
														)
												}
											</div>
										) : null
									}
									<div className={styles.actionsContainer}>
										<Button
											type="primary"
											size="large"
											onClick={getSignature}
											disabled={
												(
													!(
														sessionData?.userId === classDetails?.presenterId
														|| classDetails?.TAIds?.includes(sessionData?.userId)
													) && !hasStarted)
											}
										>
											{
												((
													sessionData?.userId === classDetails?.presenterId
													|| classDetails?.TAIds?.includes(sessionData?.userId)
												) && !hasStarted)
													? 'START CLASS' : 'JOIN CLASS'
											}
										</Button>
									</div>
								</div>
							</div>
						</div>
					)
			}
			<div className={styles.footer}>
				<div>
					<span>code</span>
					<span>quotient</span>
				</div>
			</div>
		</div>
	);
};
