import create, { UseStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { CQMainClient } from '../clients';
import { ZoomAdapterClient } from '../clients/zoom-adapter-client';
import { logger } from '../libs/utils';
import { immer } from './middleware';

enum AppAction {
	Auth = 'auth',
	SessionDataReceived = 'session-data-received',
	ClassDetailsReceived = 'class-details-received',
	ClassAllowedUserIdsReceived = 'class-allowed-user-ids-received',
	WebinarDataReceived = 'webinar-data-received',
	WebinarStatusUpdated = 'webinar-status-updated',
}

export interface AppState {
	sessionData: any | null
	classDetails: any | null
	webinarDetails: any | null

	init: () => Promise<void>
	getClassDetails: (className: string) => Promise<void>
	getClassAllowedUserIds: (className: string) => Promise<void>
	submitFeedback: ({
		zoomRoomId, fM1, fM2, fM3,
	}: any) => Promise<void>
	getWebinarDetails: (webinarId: string) => Promise<void>
	getWebinarStatus: (webinarId: string) => Promise<void>
}

const initialState: AppState = {
	sessionData: null,
	classDetails: null,
	webinarDetails: null,

	init: async () => {},
	getClassDetails: async () => {},
	getClassAllowedUserIds: async () => {},
	submitFeedback: async () => {},
	getWebinarDetails: async () => {},
	getWebinarStatus: async () => {},
};

export function createAppStore(
	cqMainClient: CQMainClient,
	zoomAdapterClient: ZoomAdapterClient,
): UseStore<AppState> {
	return create<AppState>(immer(devtools((set, get) => {
		cqMainClient.on('session-data', async (data: any) => {
			logger.log('session data ---> ', data);

			if (data) {
				set({
					sessionData: data.session,
				}, false, AppAction.SessionDataReceived);
			}
		});

		cqMainClient.on('class-details', async (data: any) => {
			logger.log('class details ---> ', data);

			if (data) {
				set({
					classDetails: data.data,
				}, false, AppAction.ClassDetailsReceived);
			}
		});

		cqMainClient.on('class-allowed-userIds', async (data: any) => {
			logger.log('class allowed user IDs ---> ', data);

			const { classDetails } = get();
			if (data) {
				set({
					classDetails: {
						...classDetails,
						allowedUserIds: data.allowedUserIds,
					},
				}, false, AppAction.ClassAllowedUserIdsReceived);
			}
		});

		zoomAdapterClient.on('webinar-details', async (data: any) => {
			logger.log('webinar details ---> ', data);

			if (data) {
				set({
					webinarDetails: data.webinar,
				}, false, AppAction.WebinarDataReceived);
			}
		});

		zoomAdapterClient.on('webinar-status', async (data: any) => {
			logger.log('webinar status ---> ', data);

			if (data) {
				const { webinarDetails } = get();

				set({
					webinarDetails: {
						...webinarDetails,
						status: data.status,
					},
				}, false, AppAction.WebinarStatusUpdated);
			}
		});

		return {
			...initialState,

			init: async () => {
				try {
					await cqMainClient.getSessionData();
				} catch (e) {
					logger.error('Error while getting session data ---> ', e);

					throw new Error('Error while getting session data!');
				}
			},

			getClassDetails: async (className: string) => {
				try {
					await cqMainClient.getClassDetails(className);
				} catch (e) {
					logger.error('Error while getting class details ---> ', e);

					throw new Error('Error while getting class details!');
				}
			},

			getClassAllowedUserIds: async (className: string) => {
				try {
					await cqMainClient.getClassAllowedUserIds(className);
				} catch (e) {
					logger.error('Error while getting class allowed user IDs ---> ', e);

					throw new Error('Error while getting allowed user IDs!');
				}
			},

			submitFeedback: async ({
				zoomRoomId, fM1, fM2, fM3,
			}: any): Promise<any> => {
				try {
					await cqMainClient.submitFeedback({
						zoomRoomId, fM1, fM2, fM3,
					});
				} catch (e) {
					logger.error('Error while submitting feedback ---> ', e);

					throw new Error('Error while submitting feedback!');
				}
			},

			getWebinarDetails: async (webinarId: string) => {
				try {
					if (!webinarId) {
						throw new Error('webinar Id not present.');
					}

					await zoomAdapterClient.getWebinarDetails(webinarId);
				} catch (e) {
					logger.error('Error while getting webinar details ---> ', e);

					throw new Error('Error while getting webinar details!');
				}
			},

			getWebinarStatus: async (webinarId: string) => {
				try {
					if (!webinarId) {
						throw new Error('webinar Id not present.');
					}

					await zoomAdapterClient.getWebinarStatus(webinarId);
				} catch (e) {
					logger.error('Error while getting webinar status ---> ', e);

					throw new Error('Error while getting webinar status!');
				}
			},
		};
	}, 'AppStore')));
}
