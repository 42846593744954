/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, message } from 'antd';
import { AppState, useAppStore } from '../../../stores';
import { logger } from '../../../libs/utils';
import { APP_CONFIG } from '../../../config';

import Like from '../../../assets/img/like.png';

import styles from './feedback.module.css';
import './index.css';
import { AppHeader } from '../../../components/app-header';

const appStoreSelector = (state: AppState) => ({
	sessionData: state.sessionData,
	classDetails: state.classDetails,

	submitFeedback: state.submitFeedback,
	getClassDetails: state.getClassDetails,
});

export const Feedback: React.FunctionComponent = () => {
	const { roomName } = useParams();

	const {
		classDetails,
		submitFeedback,
		getClassDetails,
	} = useAppStore(appStoreSelector);

	useEffect(() => {
		try {
			if (roomName && !classDetails) {
				getClassDetails(roomName);
			}
		} catch (e) {
			logger.error(e);
		}
	}, [roomName, classDetails, getClassDetails]);

	useEffect(() => {
		if (classDetails?.disableFeedback) {
			window.location.replace(APP_CONFIG.MainServerURL);
		}
	}, [classDetails]);

	const inputRef: React.RefObject<HTMLInputElement> = React.useRef(null);
	const [rate, setRate] = useState(0);
	const [audioRating, setAudioRating] = useState(0);
	const [description, setDescription] = useState('');
	const [sliderLeftPosition, setSliderLeftPosition] = useState(0);

	const handleFeedbackFormSubmit = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			if (classDetails?.zoomRoomId) {
				try {
					await submitFeedback({
						zoomRoomId: classDetails.zoomRoomId,
						fM1: rate,
						fM2: audioRating,
						fM3: description,
					});

					message.success('Feedback submitted successfully.');

					window.location.replace(APP_CONFIG.MainServerURL);
				} catch (err: any) {
					message.error(err.message);
				}
			}
		},
		[audioRating, description, rate, classDetails, submitFeedback],
	);

	const handleDescriptionChange = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value),
		[],
	);

	const handleSliderChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (inputRef.current) {
			setSliderLeftPosition(parseInt(e.target.value, 10) * (inputRef.current.offsetWidth / 10));
		}
		setRate(parseInt(e.target.value, 10));
	}, []);

	if (classDetails && !classDetails.disableFeedback) {
		return (
			<div className={styles.container}>
				<AppHeader
					className={styles.header}
					title={classDetails?.desc}
				/>
				<div className={styles.main}>
					<div className={styles.topPane}>
						<div className={styles.logo}>
							<img className="like-image" alt="like_image" src={Like} />
						</div>
						<div>
							<h1 className={styles.heading}>
								The class has ended :)
							</h1>
							<p className={styles.subheading}>
								Please take out a minute to submit your feedback.
							</p>
							<p className={styles.subheading}>
								Your feedback helps us improve the quality of learning
								and the services we render to you.
							</p>
						</div>
					</div>
					<div className={styles.bottomPane}>
						<form onSubmit={handleFeedbackFormSubmit}>
							<div className={styles.questionContainer}>
								<p className={styles.question}>
									How would you rate the class?
								</p>

								<div className={styles.rangeInputContainer}>
									<p className={styles.startRange}>0</p>
									<div className={styles.rangeInputWrapper}>
										<input
											className={styles.rangeInput}
											ref={inputRef}
											value={rate}
											onChange={handleSliderChange}
											type="range"
											min="0"
											max="10"
											step="1"
										/>

										<div
											style={{ left: `${sliderLeftPosition * 0.93}px` }}
											className={styles.rangeBadge}
										>
											{rate}
										</div>

									</div>
									<p className={styles.endRange}>10</p>
								</div>
							</div>

							<div className={styles.questionContainer}>
								<p className={styles.question}>
									How was the Audio transmission during the class?
								</p>

								<div className="rate-radio-btns">
									<div className="rate-btns">
										<label className="rate-rad-btns">
											<input onClick={() => setAudioRating(3)} type="radio" name="rating" />
											<span>Excellent</span>
										</label>
									</div>
									<div className="rate-btns">
										<label className="rate-rad-btns">
											<input onClick={() => setAudioRating(2)} type="radio" name="rating" />
											<span>Good</span>
										</label>
									</div>
									<div className="rate-btns">
										<label className="rate-rad-btns">
											<input onClick={() => setAudioRating(1)} type="radio" name="rating" />
											<span>Average</span>
										</label>
									</div>
									<div className="rate-btns">
										<label className="rate-rad-btns">
											<input onClick={() => setAudioRating(0)} type="radio" name="rating" />
											<span>Poor</span>
										</label>
									</div>
								</div>
							</div>

							<div className={styles.questionContainer}>
								<p className={styles.question}>
									Any other suggestion or feedback?
								</p>

								<textarea
									className={styles.description}
									rows={3}
									onChange={handleDescriptionChange}
									placeholder="write your suggestion here..."
								/>
							</div>

							<div className={styles.submitButtonContainer}>
								<Button type="primary" htmlType="submit">SUBMIT</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}

	return null;
};
