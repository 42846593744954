import { EventEmitter } from 'events';
import TypedEmitter from 'typed-emitter';
import axios, { AxiosInstance } from 'axios';

interface ZoomAdapterClientOptions {
	zoomAdapterURL: string
}

type ClientEvents = {
	'webinar-details': (data: any) => void
	'webinar-status': (data: any) => void
};

export class ZoomAdapterClient extends (EventEmitter as new () => TypedEmitter<ClientEvents>) {
	private _options: ZoomAdapterClientOptions;

	private _zoomAdapterApi: AxiosInstance;

	private _disposables:	(() => void)[] = [];

	// private _attachClientListeners = (): void => {};

	// private _detachClientListeners = (): void => {};

	constructor(options: ZoomAdapterClientOptions) {
		super();

		const {
			zoomAdapterURL,
		} = options;

		const zoomAdapterApi = axios.create({
			baseURL: zoomAdapterURL,
			responseType: 'json',
			// withCredentials: true,
		});

		this._zoomAdapterApi = zoomAdapterApi;
		this._options = options;

		// this._attachClientListeners();

		this._disposables.push(
			// this._detachClientListeners,
			this.removeAllListeners.bind(this),
		);
	}

	destroy = async (): Promise<void> => {
		const { _disposables } = this;

		await Promise.all(_disposables.map((disposable) => disposable()));
		this._disposables = [];
	};

	getWebinarDetails = async (webinarId: string): Promise<any> => {
		const response = await this._zoomAdapterApi.get(`/api/webinars/ongoing/${webinarId}`);

		if (response.data.error) {
			throw new Error(response.data.error);
		}

		this.emit('webinar-details', response.data);

		return response.data;
	};

	getWebinarStatus = async (webinarId: string): Promise<any> => {
		const response = await this._zoomAdapterApi.get(`/api/webinars/status/${webinarId}`);

		if (response.data.error) {
			throw new Error(response.data.error);
		}

		this.emit('webinar-status', response.data);

		return response.data;
	};
}
