import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import { ErrorScreens } from './error-screens';
import { RoomScreens } from './classroom-screens';

export const MainScreen: React.FunctionComponent = () => (
	<Routes>
		<Route path="/error/*" element={<ErrorScreens />} />

		<Route path="/room/*" element={<RoomScreens />} />

		<Route path="*" element={<Navigate replace to="/error" />} />
	</Routes>
);
