interface AppConfig {
	MainServerURL: string
	ZoomAdapterURL: string
	ZoomApiBaseURL: string
	ZoomApiKey: string
	ZoomHostEmail: string
}

export const APP_CONFIG: AppConfig = {
	MainServerURL: process.env.REACT_APP_MAIN_SERVER_URL as string,
	ZoomAdapterURL: process.env.REACT_APP_ZOOM_ADAPTER_URL as string,
	ZoomApiBaseURL: process.env.REACT_APP_ZOOM_API_BASE_URL as string,
	ZoomApiKey: process.env.REACT_APP_ZOOM_API_KEY as string,
	ZoomHostEmail: process.env.REACT_APP_ZOOM_HOST_EMAIL as string,
};

export * from './constants';
