import { CQMainClient } from '../clients';
import { ZoomAdapterClient } from '../clients/zoom-adapter-client';
import { APP_CONFIG } from '../config';
import { createAppStore } from './app-store';

export * from './app-store';

const cqMainClient = new CQMainClient({
	cqMainServerURL: APP_CONFIG.MainServerURL,
});

const zoomAdapterClient = new ZoomAdapterClient({
	zoomAdapterURL: APP_CONFIG.ZoomAdapterURL,
});

export const useAppStore = createAppStore(cqMainClient, zoomAdapterClient);
